<template>
  <vx-card class="playhala-profile-page"
           :class="{'main-box top-zero-radius': $route.name === 'playhalaProfile', 'no-box-shadow': $route.name !== 'playhalaProfile'}"
           v-if="playhala && Object.keys(playhala).length > 0">

    <profile-main-box :profile="getPlayhalaProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @playhalaTransactions="handleChangeProfileStatus('playhalaTransactions')"
                      @playhalaActivitiesLog="handleChangeProfileStatus('playhalaActivitiesLog')">

      <playhala-transactions v-if="profileStatus === 'playhalaTransactions'" />
      <playhala-activities-log v-else-if="profileStatus === 'playhalaActivitiesLog'" />
    </profile-main-box>
  </vx-card>
</template>

<script>
import {addComma, checkUserPermissions, customParseFloat, getAvatarUrl} from '@/assets/js/functions'
import {getPlayhalaProfile} from "@/http/requests/playhala";
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
import CustomIcon from '../../../../components/customIcon/customIcon'
import PlayhalaActivitiesLog from "@/views/admin/playhala/activitiesLog/playhalaActivitiesLog.vue";
import PlayhalaTransactions from "@/views/admin/playhala/transactions/playhalaTransactions.vue";

export default {
  name: 'playhalaProfile',
  components: {
    PlayhalaTransactions,
    PlayhalaActivitiesLog,
    CustomIcon,
    ProfileMainBox
  },
  metaInfo () {
    return {
      title: this.playhala ? this.playhala.name : this.$t('playhala.profile.title')
    }
  },
  data () {
    return {
      profileStatus: 'playhalaTransactions',
      actions: {
        toolbar: [],
        leftToolbar: []
      },
      playhala: null,
      actionsList: [
        {
          event: 'playhalaTransactions',
          name: 'playhalaTransactions',
          header: this.$t('playhala.profile.actions.transactions'),
          extra: {
            value: '',
            classes: 'text-success'
          },
          i18n: '',
          icon: 'TURNOVER',
          iconPack: 'useral'
        },
        {
          event: 'playhalaActivitiesLog',
          name: 'playhalaActivitiesLog',
          header: this.$t('playhala.profile.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ]
    }
  },
  created () {
    if (this.$route.name === 'playhalaProfile') {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })
    }

    this.getPlayhalaInfo()
  },
  computed: {
    getPlayhalaProfile () {
      const playhala = {
        avatar: this.playhala.avatar ? getAvatarUrl(this.playhala.avatar) : require('@/assets/images/no-image.png'),
        name: this.playhala.name || '',
        importantData: [
          {
            value: this.playhala.transactionCount,
            key: this.$t('playhala.profile.importantData.transactionCount'),
          }
        ],
        details: [],
      }

      return playhala
    }
  },
  methods: {
    getPlayhalaInfo () {

      getPlayhalaProfile().then(res => {
        const playhala = res.data.data

        this.playhala = {
          name: this.$t('playhala.title'),
          transactionCount: playhala.transaction_count,
          balance: playhala.balance
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('playhalaTransactions')
        if (balance_index > -1) {
          this.actionsList[balance_index].extra = {
            value: customParseFloat(this.playhala.balance || 0),
            type: 'price'
          }
        }

        if (!this._isBeingDestroyed) {
          this.$store.dispatch('setPageTitle', this.playhala.name)
        }
      })
    },
    handleChangeProfileStatus (status) {
      this.profileStatus = ''
      this.$nextTick(() => {
        this.profileStatus = status
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.playhala-profile-page {

  .vx-card__body {
    padding: 0 !important;

    .profile-main-box2 {
      padding: 0;
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
