import { render, staticRenderFns } from "./playhalaTransactions.vue?vue&type=template&id=61b463e4&scoped=true&"
import script from "./playhalaTransactions.vue?vue&type=script&lang=js&"
export * from "./playhalaTransactions.vue?vue&type=script&lang=js&"
import style0 from "./playhalaTransactions.vue?vue&type=style&index=0&id=61b463e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b463e4",
  null
  
)

export default component.exports