<template>
  <div class="playhala-transactions" :class="{'in-modal': $route.name !== 'playhalaTransactions', 'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'playhalaTransactions'}">
    <draggable-dynamic-table ref="playhalaTransactions"
                             class="playhala-transactions"
                             :in-modal="$route.name !== 'playhalaTransactions'"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @load:more="getTransactions"/>
  </div>
</template>

<script>
import axios from "axios";
import {convertUtcToLocale, getTimeFromServer, hideLoading, showLoading} from "../../../../assets/js/functions";
import {getPlayhalaTransactions} from "@/http/requests/playhala";
import setDocumentDescription from '../../../../mixins/setDocumentDescription'

export default {
  name: 'playhalaTransactions',
  metaInfo () {
    return {
      title: this.$t('playhala.transactions.title')
    }
  },
  mixins: [setDocumentDescription],
  data () {
    return {
      options: {
        id: 'playhalaTransactions',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'transactions.table.header.balance',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'price',
          i18n: 'transactions.table.header.price',
          width: '120px',
          minWidth: 120,
          sortable: true,
          locked: true
        },
        {
          field: 'description',
          i18n: 'transactions.table.header.description',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
        },
        {
          field: 'date',
          i18n: 'transactions.table.header.date',
          align: 'center',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          sortable: true,
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      bank: null,
      data: [],
      filters: {},
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          toolbar: [],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            },
            {
              id: 'routeBackBTN',
              route: {name: 'playhalaProfile'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.$route.name !== 'playhalaTransactions') {
      const backIndex = this.actions[0].leftToolbar.map(elm => elm.id).indexOf('routeBackBTN')
      if (backIndex > -1) {
        this.actions[0].leftToolbar.splice(backIndex, 1)
      }
    }
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('setPageTitle', this.$t('playhala.transactions.title'))
    })

    this.getTransactions()
  },
  methods: {
    getTransactions () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.playhalaTransactions && this.data.length === 0) {
            this.$refs.playhalaTransactions.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.playhalaTransactions && this.data.length > 0) {
            this.$refs.playhalaTransactions.loadMoreStatus = 'Loading'
          }

          getPlayhalaTransactions(this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0

            const transactions = response.data.data
            transactions.forEach(transaction => {
              if (transaction.document.reference) transaction.document.reference.reference_type = transaction.document.reference_type

              this.data.push({
                docNumber: {
                  value: transaction.document.id,
                  route: {name: 'document', params: {id: transaction.document.id}},
                  target: '_blank'
                },
                date: {
                  value: convertUtcToLocale(transaction.document.date, this.$localeValidator('moment.dateTime')),
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                description: this.generateDocumentDescription(transaction.document, {hasRoute: true}),
                price: {
                  type: 'price',
                  classes: transaction.price < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.price)
                },
                balance: {
                  type: 'price',
                  classes: transaction.balance < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.balance)
                }
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('docNumber')

              this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
            }

            if (this.$refs.playhalaTransactions) this.$refs.playhalaTransactions.loadMoreStatus = ''
          }).catch((error) => {
            console.log(error)
            if (this.$refs.playhalaTransactions && !axios.isCancel(error)) this.$refs.playhalaTransactions.loadMoreStatus = 'Danger'
          })
        }
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .playhala-transactions {
    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
